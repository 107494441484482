﻿@import 'mixins';

html {
    height: 100%;
}

body {
    margin: 0;
    min-height: 100%;
}

img {
    max-width: 100%;
}

body.landing-layout {
    height: 100%;

    .body-content {
        max-width: none;
    }

    .content-box {
        background: white;
        width: 100%;

        form {
            padding: 1.5rem 2rem 1rem 2rem;
        }
    }

    .content-box-header {
        text-align: center;
        padding: 1rem 0.5rem;

        img {
            max-width: 250px;
        }
    }

    .validation-summary-errors ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .validation-summary-valid.alert {
        display: none;
    }

    .client-project {
        margin-bottom: 20px;
        padding: 10px;
    }
    
    @media screen and (min-width: 576px) {
        .body-content {
            min-height: 100%;
            padding: 15px;
        }

        .content-box {
            width: auto;

            .form-single {
                width: 400px;
            }
            .form-double {
                width: 500px;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .content-box .form-double {
            width: 600px;
        }
    }

    @media screen and (min-width: 992px) {
        .content-box .form-double {
            width: 700px;
        }
    }

    @media screen and (min-width: 1200px) {
        .content-box .form-double {
            width: 800px;
        }
    }
}

.container {
    @media screen and (min-width: 576px) { max-width: 720px; }
    @media screen and (min-width: 768px) { max-width: 960px; padding-left:30px; padding-right:30px; }
    @media screen and (min-width: 992px) { max-width: 1140px; }
    @media screen and (min-width: 1200px) { max-width: 1500px; }
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
    white-space: normal;
}

.navbar-collapse {
    @include justify-content(left);
}

.navbar {
    .navbar-nav{
        margin-left:20px;
    }
    .email-link{
        text-transform:capitalize;
    }
    .navbar-brand {
        padding: 0;
        max-width: 45%;

        img {
            display: block;
            max-width: 100%;
            max-height: 2rem;
        }
    }

    &.navbar-expand-lg .navbar-nav {
        .nav-link {
            padding-right: .5rem;
            padding-left: .5rem;
        }

        @media screen and (max-width: 991px) {
            margin-top: 5px;
        }
    }

    @media screen and (min-width: 992px) {
        &.navbar-expand-lg .navbar-collapse.d-lg-none {
            display: none !important;
        }
    }
}

.header-offer {
    @media screen and (max-width: 575px) {
        text-align: center;
    }

    @media screen and (max-width: 991px) {
        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.section-1, .section-2, .section-highlight {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media screen and (max-width: 576px) {
    .section-highlight {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.sales-banner {
    margin: 0 auto;
    padding: 30px 20px;

    @media screen and (min-width: 576px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media screen and (min-width: 768px) {
        max-width: 950px;
    }
}

.sales-stacks-perspective {
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.sales-stacks-container {
    position: relative;
    margin: 0 auto;

    .sales-stacks-item-header {
        padding: 5px 10px;
        text-align: left;
    }

    .sales-stacks-item-1,
    .sales-stacks-item-3,
    .sales-stacks-item-4,
    .sales-stacks-item-5 {
        height: 400px;
        margin-bottom: 20px;
        transition: all 1000ms ease;
        transform-style: preserve-3d;
    }

    .sales-stacks-item-2 {
        height: 500px;
        margin-bottom: 20px;
        transform-style: preserve-3d;
    }

    @media screen and (min-width: 768px) {
        width: 600px;
        height: 650px;

        .sales-stacks-item-1 {
            position: absolute;
            z-index: 1999;
            top: -5px;
            right: 5px;
            width: 150px;
            height: 200px;
            transform: rotateX(40deg) rotateY(7deg) rotateZ(-27deg);
        }

        .sales-stacks-item-2 {
            position: relative;
            top: -200px;
            left: 60px;
            width: 700px;
            transform: rotateX(40deg) rotateY(7deg) rotateZ(-27deg) translateZ(-400px);
        }

        .sales-stacks-item-3 {
            position: absolute;
            z-index: 9999;
            top: 320px;
            left: -35px;
            width: 100px;
            height: 150px;
            transform: rotateX(40deg) rotateY(7deg) rotateZ(-27deg);
        }

        .sales-stacks-item-4 {
            position: absolute;
            top: 410px;
            left: 20px;
            width: 300px;
            height: 200px;
            transform: rotateX(40deg) rotateY(7deg) rotateZ(-27deg);
        }

        .sales-stacks-item-5 {
            position: absolute;
            top: 239px;
            left: 300px;
            width: 300px;
            height: 320px;
            transform: rotateX(40deg) rotateY(7deg) rotateZ(-27deg);
        }
    }

    @media screen and (min-width: 992px) {
        width: 550px;

        /*.sales-stacks-item-1 {
            right: 170px;
            width: 200px;
            height: 250px;
        }*/
    }
}


.expandable-item, .link-item {
    margin-bottom: 20px;

    .expandable-item-header, .link-item-header {
        @include display-flex();
        @include align-items(flex-end);
        padding: 10px;

        .expandable-item-header-field, .link-item-header-field {
            padding-right: 10px;

            .expandable-item-header-text, .link-item-header-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .expand-btn, .link-btn {
            @include circle();
            width: 40px;
            border-radius: 50%;

            i, svg {
                transition: all ease 300ms;
                transform: translateY(1px);
            }

            &.collapsed {
                i, svg {
                    transform: rotate(-90deg) translateY(1px);
                }
            }
        }
    }

    .expandable-item-body .expandable-item-body-content {
        padding: 10px;
        position:relative;
    }
}

.quote-section-header {
    .quote-section-header-main {
        @include flex-grow(1);
    }

    .quote-section-header-price {
        margin-right: 5%;
        text-align: right;
    }
}

.quote-summary {
    @include display-flex();
    @include flex-direction(column);
    font-size: 13px;
    text-align: center;

    .summary-title{
        font-size: 20px;
        background-color: #222;
        padding: 15px;
        font-weight: 500;
    }
    .quote-summary-totals {
        padding: 15px;

        .price {
            margin-top: 20px;
            font-size: 28px;
        }

        .description {
            margin-top: -5px;
            font-size: 16px;
            line-height: 26px;
        }

        .tip {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .quote-summary-key-points {
        position: relative;
        padding: 20px;
        font-size: 14px;
        line-height: 26px;

        ul {
            list-style: none;
            text-align: left;
            margin-bottom: 10px;
            padding-left: 0;

            li {
                svg.svg-inline--fa {
                    font-size: 110%;
                    margin-right: 10px;
                }

                &::before {
                    display: none;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    content: '\f00c'
                }
            }
        }
    }

    .quote-summary-launch {
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 12px;
        line-height: 20px;
    }

    .quote-summary-actions a {
        display: block;
        padding: 15px;
        font-size: 18px;
    }

    /*@media screen and (min-width: 576px) {
        & {
            @include flex-direction(row);
        }

        .quote-summary-section {
            width: 50%;
        }
    }*/

    @media screen and (min-width: 992px) {
        & {
            @include flex-direction(column);
        }

        .quote-summary-section {
            width: 100%;
        }
    }
}

.project-process {
    @include display-flex();
    @include flex-direction(column);
    max-width: 350px;
    margin: 0 auto;

    .project-process-header,
    .project-process-block {
        @include display-flex();
        @include flex-direction(row);
    }

    .project-process-header {
        text-align: center;

        .project-process-header-you,
        .project-process-header-us {
            @include display-flex();
            @include flex-direction(row);
            @include justify-content(center);
            @include align-items(center);

            h2 {
                margin-bottom: 0;
            }
        }

        .project-process-header-you {
            width: 39%;
        }
        .project-process-header-us {
            @include flex-grow(1);
            width: 59%;
        }
    }

    .project-process-block {
        min-height: 200px;

        .project-process-block-you {
            @include display-flex();
            @include flex-direction(column);
            @include align-items(center);
            width: 39%;

            .project-process-block-you-line {
                @include flex-grow(1);
                width: 2px;
                position: relative;

                .project-process-block-you-line-arrow-horizontal {
                    display: none;
                }

                .project-process-block-you-line-arrow-vertical {
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%) translateY(50%);
                }
            }

            .project-process-block-you-circle {
                @include circle();
                @include display-flex();
                @include justify-content(center);
                @include align-items(center);
                width: 100%;
                position: relative;

                .project-process-block-you-circle-top-horizontal,
                .project-process-block-you-circle-top-vertical,
                .project-process-block-you-circle-bottom-horizontal,
                .project-process-block-you-circle-bottom-vertical {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .project-process-block-you-circle-top-horizontal,
                .project-process-block-you-circle-top-vertical {
                    top: 5%;
                }

                .project-process-block-you-circle-bottom-horizontal,
                .project-process-block-you-circle-bottom-vertical {
                    bottom: 5%;
                }

                .project-process-block-you-circle-above-horizontal,
                .project-process-block-you-circle-top-horizontal,
                .project-process-block-you-circle-bottom-horizontal {
                    display: none;
                }

                .project-process-block-you-circle-label {
                    text-align: center;
                    padding: 10px;
                    line-height: 1.1;
                }
            }

            .project-process-block-you-space {
                @include flex-grow(1);
            }
        }

        .project-process-block-us {
            @include flex-grow(1);
            @include display-flex();
            @include flex-direction(column);
            @include justify-content(center);
            width: 59%;

            .project-process-block-us-item {
                width: 100%;
                padding: 5px;
            }
        }
    }

    .project-process-divider {
        width: 4px;
    }

    @media screen and (min-width: 992px) {
        & {
            @include flex-direction(row);
            margin-left: 3%;
            margin-right: 3%;
            padding-top: 30px;
            max-width: unset;
        }

        .project-process-header {
            @include flex-direction(column);
            width: 10%;

            .project-process-header-you,
            .project-process-header-us {
                @include flex-direction(column);
                width: auto;
                
                h2 {
                    margin-right: 25px;
                }
            }

            .project-process-header-you {
                height: 130px;
            }

            .project-process-header-us {
                min-height: 130px;
            }
        }

        .project-process-block {
            @include flex-direction(column);
            min-height: unset;
            width: 18%;

            .project-process-block-you {
                @include flex-direction(row);
                width: auto;
                height: 130px;

                .project-process-block-you-line {
                    width: auto;
                    height: 2px;

                    .project-process-block-you-line-arrow-horizontal {
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        transform: translateX(-50%) translateY(-50%);
                    }

                    .project-process-block-you-line-arrow-vertical {
                        display: none;
                    }
                }

                .project-process-block-you-circle {
                    width: 130px;

                    .project-process-block-you-circle-above-horizontal {
                        display: block;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: -30px;
                    }

                    .project-process-block-you-circle-top-horizontal,
                    .project-process-block-you-circle-bottom-horizontal {
                        display: block;
                    }

                    .project-process-block-you-circle-top-vertical,
                    .project-process-block-you-circle-bottom-vertical {
                        display: none;
                    }
                }
            }

            .project-process-block-us {
                @include justify-content(flex-start);
                @include align-items(center);
                width: auto;
                min-height: 200px;

                .project-process-block-us-item {
                    width: 95%;
                }
            }
        }

        .project-process-divider {
            width: auto;
            height: 4px;
        }
    }
    
}

@media screen and (min-width: 992px) {
    .project-process .project-process-block .project-process-block-item.project-process-block-item-you .project-process-block-item-circle .project-process-block-item-circle-label {
        transform: translateY(-50%) rotate(-30deg);
    }
    .project-process .project-process-block .project-process-block-item.project-process-block-item-us .project-process-block-item-circle .project-process-block-item-circle-label {
        transform: translateY(-50%) rotate(30deg);
    }
}

.project-stages {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);

    .project-stage {
        @include circle();

        position: relative;
        text-align: center;
        padding: 10px;
        width: 41%;
        margin-right: 60%;

        @include display-flex();
        @include align-items(center);
        @include justify-content(center);
        @include flex-grow(0);

        .project-stage-info {
            position: absolute;
            height: 150%;
            top: -25%;
            left: 110%;
            font-size: 11px;
            text-align: left;

            @include display-flex();
            @include flex-direction(column);
            @include justify-content(center);

            .project-stage-info-tag {
                white-space: nowrap;

                &:not(:last-child) {
                    margin-bottom: 0.5rem;
                }
            }
        }

        .project-stage-icon {
            position: absolute;
            top: -5%;
            left: -5%;

            .svg-inline--fa {
                font-size: 1.25em;
            }
        }
    }

    .project-stage-line {
        @include flex-grow(0);
        width: 2px;
        height: 1rem;
        margin-left: 20.5%;
        margin-right: 80.5%;
    }

    @media screen and (min-width: 400px) {
        .project-stage {
            width: 33%;
            margin-right: 50%;

            .project-stage-info {
                left: 120%;
                font-size: 12px;
            }
        }
        
        .project-stage-line {
            margin-left: 16.5%;
            margin-right: 66.5%;
        }
    }

    @media screen and (min-width: 576px) {
        .project-stage {
            width: 23%;
            margin-right: 40%;

            .project-stage-icon .svg-inline--fa {
                font-size: 1.5em;
            }
        }
        
        .project-stage-line {
            margin-left: 11.5%;
            margin-right: 51.5%;
        }
    }

    @media screen and (min-width: 768px) {
        .project-stage {
            width: 17%;
            margin-right: 30%
        }
        
        .project-stage-line {
            margin-left: 8.5%;
            margin-right: 38.5%;
        }
    }

    @media screen and (min-width: 992px) {
        & {
            @include flex-direction(row);
            @include align-items(center);
            margin-left: 15px;
            margin-right: 15px;
        }

        .project-stage {
            width: 13%;
            padding: 15px;
            margin-bottom: 100px;
            margin-right: 0;

            .project-stage-info {
                height: auto;
                width: 150%;
                left: -25%;
                top: 110%;
                text-align: center;
            }
        }
        
        .project-stage-line {
            @include flex-grow(1);
            width: auto;
            min-width: 1%;
            height: 2px;
            margin-bottom: 100px;
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media screen and (min-width: 1200px) {
        .project-stage {
            width: 11%;

            .project-stage-icon {
                top: -4%;
                left: -4%;
            }
        }
    }

    @media screen and (min-width: 1350px) {
        .project-stage .project-stage-icon {
            top: -3%;
            left: -3%;
        }
    }
}

.client-header {
    .client-header-name {
        @include flex-grow(1);
        min-width: 100px;
    }

    .client-header-contact-name,
    .client-header-email,
    .client-header-phone,
    .client-header-website,
    .client-header-active {
        display: none;
        @include flex-shrink(0);
    }

    @media screen and (min-width: 400px) {
        .client-header-active {
            display: block;
            @include flex-basis(100px);
        }
    }

    @media screen and (min-width: 576px) {
        .client-header-website {
            display: block;
            @include flex-basis(220px);
        }
    }

    @media screen and (min-width: 768px) {
        .client-header-contact-name {
            display: block;
            @include flex-basis(180px);
        }
    }

    @media screen and (min-width: 992px) {
        .client-header-email {
            display: block;
            @include flex-basis(200px);
        }
    }

    @media screen and (min-width: 1200px) {
        .client-header-contact-name {
            @include flex-basis(15%);
        }

        .client-header-email {
            @include flex-basis(18%);
        }

        .client-header-phone {
            display: block;
            @include flex-basis(15%);
        }

        .client-header-website {
            @include flex-basis(20%);
        }
    }
}

.client-body-header {
    @include display-flex();
    @include flex-direction(row);
    @include align-items(center);
    margin-bottom: 10px;

    .client-body-heading {
        @include flex-grow(1);
    }
}

.client-project-header {
    .client-project-header-name {
        @include flex-grow(1);
    }

    .client-project-header-type {
        @include flex-basis(30%);
    }
}

.client-user-header {
    .client-user-header-email {
        @include flex-grow(1);
        min-width: 100px;
    }

    .client-user-header-status {
        @include flex-grow(0);
        min-width: 70px;
    }

    .client-user-header-name,
    .client-user-header-company-name {
        display: none;
    }

    @media screen and (min-width: 576px) {
        .client-user-header-name {
            display: block;
            @include flex-basis(40%);
        }
    }

    @media screen and (min-width: 768px) {
        .client-user-header-name {
            @include flex-basis(30%);
        }

        .client-user-header-company-name {
            display: block;
            @include flex-basis(30%);
        }
    }
    /*@media screen and (min-width: 1200px) {
        .client-user-header-company-name {
            display: none;
        }
    }*/
}

.field-group {
    @include display-flex();
    @include flex-direction(column);
    margin-bottom: 10px;

    label {
        margin-bottom: 0;
    }

    .field-label, .field-value {
        width: 100%;
    }

    @media screen and (min-width: 576px) {
        & {
            @include flex-direction(row);
        }

        .field-label {
            position: relative;
            width: 40%;
            padding-right: 10px;

            i, svg {
                position: absolute;
                top: 0.55em;
                right: 10px;
            }
        }
        .field-value {
            width: 60%;
            padding-left: 10px;
            border-left: 1px solid;
        }
    }
}

ul.simple {
    margin: 0;
    padding: 0;
    list-style: none;
}

.text-capitalise {
    text-transform: capitalize;
}

@media screen and (min-width: 576px) {
    .text-sm-left { text-align: left; }
    .text-sm-center { text-align: center; }
    .text-sm-right { text-align: right; }
    .text-sm-justify { text-align: justify; }
}

@media screen and (min-width: 768px) {
    .text-md-left { text-align: left; }
    .text-md-center { text-align: center; }
    .text-md-right { text-align: right; }
    .text-md-justify { text-align: justify; }
}

@media screen and (min-width: 992px) {
    .text-lg-left { text-align: left; }
    .text-lg-center { text-align: center; }
    .text-lg-right { text-align: right; }
    .text-lg-justify { text-align: justify; }
}

@media screen and (min-width: 1200px) {
    .text-xl-left { text-align: left; }
    .text-xl-center { text-align: center; }
    .text-xl-right { text-align: right; }
    .text-xl-justify { text-align: justify; }
}


/* admin styles */
.admin-quote-section {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-top: 15px;
    position: relative;
}
.delete-quote-section {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #666;
}
.admin-section-details-wrapper {
    /*display:none;*/
}
.admin-edit-section-collapse {
    position: absolute;
    top: 8px;
    right: 10px;
}
.admin-quote-section {
    .btn {
        i, svg {
            transition: all ease 300ms;
        }

        &.collapsed {
            i, svg {
                transform: rotate(-90deg);
            }
        }
    }
}
.proj-title-wrapper {
    position: relative;
    width: auto;
    display: inline-block;

    .admin-edit-proj-btn {
        position: absolute;
        top: 0;
        right: -30px;
        color: #666;
        display: none;
    }
    .admin-edit-proj-btn:hover {
        color: #000;
    }
}

.project-summary-wrapper:hover .admin-edit-proj-btn {
    display: block;
}

.modal-header {
    background-color: #222;
    color: #fff;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;

    .close {
        color: #fff;

        &:not(:disabled):not(.disabled) {
            &:focus,
            &:hover {
                color: #fff;
            }
        } 
    }
}

.modal-body {
    background-color: #f4f4f4;
}
.modal-footer {
    background-color: #f4f4f4;
}

.quote-action-state {
    display: inline-block;

    .state-icon.requires-action {
        color: #f4a742;
        font-size: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
    .state-icon.confirmed {
        color: #1eb20a;
        font-size: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
}
.state-hidden {
    display: none;
}

.quote-section-header-actions .expand-btn {
    vertical-align: middle;
    font-size: 23px;
    color: #555;
    background-color: transparent;
}
.quote-section-header-actions .expand-btn:focus {
    outline: none;
    box-shadow: none;
}

.project-info-section {
    margin-bottom: 40px;
}

.title-label {
    position: absolute;
    top: -21px;
    left: -1px;
    color: #555;
    font-weight: 300;
}
.spec-block {
    width: 40%;
    display: inline-block;
    vertical-align: top;
}
.quote-section-client-actions {
    text-align: right;
}
.image-preview-wrapper {
    text-align: center;
    width: 100%;
    padding: 10px;
}

.signature-pad{
    margin:20px 0;
    text-align:center;
}

.signature-pad-body {
    text-align: center;
}
.signature-pad-note{
    color: #ccc;
    font-size: 12px;
}
.signature-pad-btn{
    border: 1px solid #ccc;
    font-family: Montserrat,sans-serif;
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 24px;
    background-color: #f4f4f4;
    color: #555;
}


.front-end-modal{
    .modal-content{
        background-color:#f9f9f9;
        color:#222;
    }
    .modal-body{
        background-color:#f9f9f9;
        padding:2rem;
    }
    .close{
        position:absolute;
        top:5px;
        right:10px;
    }
    canvas{
        border: 1px solid #eee;
        background-color: #fff;
    }
    .payment-text{
        text-align:center;
    }
}

.indented{
    margin-left:25px;
}


.delete-client{
    position: absolute;
    right: 8px;
    bottom: 5px;
    color:#555;
}