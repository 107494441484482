﻿@import 'mixins';

body.corin-design {
    $cd-blue: rgb(66, 83, 255);
    $cd-red: #fb4444;

    /*
    a, a:visited {
        color: white;
    }

    a:hover, a:active {
        color: white;
    }
    */

    &.landing-layout {
        @media screen and (min-width: 400px) {
            .body-content {
                background-color: $cd-blue;
            }
        }

        .content-box-header {
            background-color: $cd-blue;
        }
    }

    .btn-primary {
        @include button($cd-blue, $cd-blue, white, $cd-blue, $cd-blue, white, $cd-blue, $cd-blue, white);
    }

    .btn-outline-primary {
        @include button(transparent, $cd-blue, $cd-blue, $cd-blue, $cd-blue, white, $cd-blue, $cd-blue, white);
    }

    $nav-text-colour: white;
    $nav-text-active-colour: white;
    $nav-background-colour: $cd-blue;
    .navbar {
        background: $nav-background-colour;
        color: $nav-text-colour;

        .navbar-nav {
            .nav-link {
                color: $nav-text-colour;

                &:hover, &:focus, &:active {
                    color: $nav-text-active-colour;
                }
            }

            .show > .nav-link,
            .active > .nav-link,
            .nav-link.show,
            .nav-link.active {
                color: $nav-text-active-colour;
            }
        }

        .navbar-text {
            color: $nav-text-colour;
            a {
                color: $nav-text-colour;

                &:hover, &:focus, &:active {
                    color: $nav-text-active-colour;
                }
            }
        }

        .navbar-toggler {
            color: $nav-text-colour;
            border-color: $nav-text-colour;
        }

        .navbar-toggler-icon {
            background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
    }

    .header-offer {
        background: $cd-red;
        color: white;
    }

    .section-1 {
        background: white;
    }

    .section-2 {
        background: $cd-blue;
    }

    .section-highlight {
        background: $cd-red;
    }
}
