﻿@mixin button($background, $border, $text, $hover-background, $hover-border, $hover-text, $active-background, $active-border, $active-text) {
    color: $text;
    background-color: $background;
    border-color: $border;

    &:hover {
        color: $hover-text;
        background-color: $hover-background;
        border-color: $hover-border;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 .2rem rgba($border, .5);
    }

    &.disabled,
    &:disabled {
        color: $text;
        background-color: $background;
        border-color: $border;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $active-text;
        background-color: $active-background;
        border-color: $active-border;

        &:focus {
            box-shadow: 0 0 0 .2rem rgba($border, .5);
        }
    }
}

@mixin circle {
    border-radius: 50%;

    &::before {
        content: '';
        float: left;
        width: 1px;
        margin-left: -1px;
        padding-top: 100%;
    }
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

// Flexbox mixins from https://github.com/mastastealth/sass-flex-mixin

@mixin display-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

%display-flex { @include display-flex; }

@mixin display-inline-flex {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

%display-inline-flex { @include display-inline-flex; }

@mixin flex-direction($value: row) {
    @if $value == row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
    } @else if $value == column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
    } @else if $value == column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
    } @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
    }
    -webkit-flex-direction: $value;
    -moz-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}
// Shorter version:
@mixin flex-dir($args...) { @include flex-direction($args...); }

@mixin flex-wrap($value: nowrap) {
    // No Webkit Box fallback.
    -webkit-flex-wrap: $value;
    -moz-flex-wrap: $value;
    @if $value == nowrap {
        -ms-flex-wrap: none;
    } @else { 
        -ms-flex-wrap: $value; 
    }
    flex-wrap: $value;
}

@mixin flex-flow($values: (row nowrap)) {
    // No Webkit Box fallback.
    -webkit-flex-flow: $values;
    -moz-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
}

@mixin order($int: 0) {
    -webkit-box-ordinal-group: $int + 1;
    -webkit-order: $int;
    -moz-order: $int;
    -ms-flex-order: $int;
    order: $int;
}

@mixin flex-grow($int: 0) {
    -webkit-box-flex: $int;
    -webkit-flex-grow: $int;
    -moz-flex-grow: $int;
    -ms-flex-positive: $int;
    flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
    -webkit-flex-shrink: $int;
    -moz-flex-shrink: $int;
    -ms-flex-negative: $int;
    flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    -moz-flex-basis: $value;
    -ms-flex-preferred-size: $value;
    flex-basis: $value;
}

@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;		
    } @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    -moz-justify-content: $value;
    justify-content: $value;
}
// Shorter version:
@mixin flex-just($args...) { @include justify-content($args...); }

@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    -moz-align-items: $value;
    align-items: $value;
}

@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    -moz-align-self: $value;
    @if $value == flex-start {
        -ms-flex-item-align: start;
    } @else if $value == flex-end {
        -ms-flex-item-align: end;
    } @else {
        -ms-flex-item-align: $value;
    }
    align-self: $value;
}

@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    -moz-align-content: $value;
    @if $value == flex-start {
        -ms-flex-line-pack: start;
    } @else if $value == flex-end {
        -ms-flex-line-pack: end;
    } @else {
        -ms-flex-line-pack: $value;
    }
    align-content: $value;
}