﻿@import 'mixins';

// for footer
body.main-app{
    padding-bottom:60px; 
    position:relative;
}

body.ecomsilver {
    $es-pink: #f7126d;

    .footer{
        padding:15px;
        background-color:#000;
        color:#555;
        text-align:right;
        vertical-align:middle;
        margin-top:60px;
        font-size:14px;
        position:absolute;
        width:100%;
        bottom:0;
        height:60px;
    }

    .body-content{
        padding-bottom:30px;
    }

    /*
    a, a:visited {
        color: #222;
    }

    a:hover, a:active {
        color: #f7126d;
    }
    */

    a.link-btn{
        color:$es-pink;
    }

    & {
        font-family: 'Lato', sans-serif;
        color: #222;
        font-size: 16px;
        line-height: 26px;
  
    }

    p{
        line-height:24px;
    }

    h1, h2, h3 {
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
    }

    h1 {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
    }

    h2 {
        font-size: 26px;
        font-weight: 700;
        line-height: 32px;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
    }
    
    h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
    }

    &.landing-layout {
        @media screen and (min-width: 400px) {
            .body-content {
                background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url('/Content/Companies/client-mcgowan-rutherford.jpg');
                background-image: linear-gradient(180deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url('/Content/Companies/client-mcgowan-rutherford.jpg');
                background-position: 0px 0px, 50% 50%;
                background-size: auto, cover;
                background-repeat: repeat, no-repeat;
                background-attachment: scroll, scroll;
            }
        }

        .content-box-header {
            box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, .12);
        }

        .client-project {
            border-radius: 5px;
            border: 2px solid #eee;
            background: #eee;

            .client-project-label {
                font-family: 'Montserrat', sans-serif;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }

    .btn-primary {
        @include button($es-pink, $es-pink, white, transparent, $es-pink, $es-pink, transparent, $es-pink, $es-pink);
    }

    .btn-outline-primary {
        @include button(transparent, $es-pink, $es-pink, $es-pink, $es-pink, white, $es-pink, $es-pink, white);
    }

    .btn-primary, .btn-outline-primary, .btn-success, .btn-outline-success {
        border-width: 2px;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 24px;

        &.btn-sm {
            font-size: 12px;
            line-height: 18px;
        }
    }

    .btn-go {
        border-width: 2px;
        font-family: Montserrat,sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 24px;
        background-color: #28a745;
        color: #fff;
        padding: 14px 37px;

        &.btn-sm {
            font-size: 12px;
            line-height: 18px;
        }
    }

    $nav-text-colour: #222;
    $nav-text-hover-colour: $es-pink;
    $nav-text-active-colour: white;
    $nav-background-colour: white;
    $nav-background-active-colour: $es-pink;
    .navbar {
        background: $nav-background-colour;
        color: $nav-text-colour;
        box-shadow: 0px 1px 10px 0 rgba(51,51,51,0.5);

        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;

        .navbar-nav {
            .nav-link {
                color: $nav-text-colour;

                &:hover, &:focus, &:active {
                    color: $nav-text-hover-colour;
                }
            }

            .show > .nav-link,
            .active > .nav-link,
            .nav-link.show,
            .nav-link.active {
                color: $nav-text-hover-colour;
            }
        }

        .navbar-text {
            color: $nav-text-colour;
            a {
                color: $nav-text-colour;

                &:hover, &:focus, &:active {
                    color: $nav-text-hover-colour;
                }
            }
        }

        .navbar-toggler {
            color: $nav-text-colour;
            border-color: $nav-text-colour;
        }

        .navbar-toggler-icon {
            background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(32, 32, 32)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }

        .dropdown-menu .dropdown-item {
            font-size: 13px;
            font-weight: 600;
            padding: .25rem 1rem;

            &:hover, &:focus {
                color: $nav-text-hover-colour;
                background-color: $nav-background-colour;
            }

            &:active {
                color: $nav-text-active-colour;
                background-color: $nav-background-active-colour
            }
        }

        .nav-link.user-nav{
            background-color: #f7126d;
            color: #fff;
            border-radius: 4px;
        }
        .nav-link.user-nav:hover, .nav-link.user-nav:focus{
            color: #fff;
        }


    }

    .header-offer {
        background: $es-pink;
        color: white;
    }

    .section-1 {
        background: white;
    }

    .section-2 {
        background: #eee;
    }

    .section-highlight {
        background: $es-pink;
    }

    .sales-banner {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        line-height: 26px;

        .sales-banner-heading {
            font-weight: 600;
            font-size: 30px;
            line-height: 40px;
        }

        @media screen and (min-width: 576px) {
            .sales-banner-heading {
                font-size: 45px;
                line-height: 54px;
            }
        }

        @media screen and (min-width: 768px) {
            font-size: 20px;
            line-height: 28px;
        }
    }

    /*.sales-stacks-container {
        .sales-stacks-item-header {
            background-color: $es-pink;
            color: white;
        }

        .sales-stacks-item-1 {
            background-size: 60%;
            background-color: white;
            background-image: url('//ecomsilver.com/images/lamp.jpg');
            background-position: 50% 50%;
            background-repeat: no-repeat;
            box-shadow: -25px 25px 50px 0 rgba(0, 0, 0, .17);
        }

        .sales-stacks-item-2 {
            background-image: url('//ecomsilver.com/images/baneer-prod.png');
            background-position: 70% 50%;
            background-size: 99%;
            background-repeat: no-repeat;
            box-shadow: 5px 5px 43px -7px rgba(0, 0, 0, .12);
        }

        .sales-stacks-item-3 {
            background-color: #f0f0f0;
            background-image: url('//ecomsilver.com/images/clock.png');
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
            box-shadow: 0 49px 50px -22px rgba(0, 0, 0, .12);
        }

        .sales-stacks-item-4 {
            background-color: white;
            background-image: url('//ecomsilver.com/images/2prod.png');
            background-position: 50% 83%;
            background-size: 90%;
            background-repeat: no-repeat;
        }

        .sales-stacks-item-5 {
            background-color: #f0f0f0;
            background-image: url('//ecomsilver.com/images/4prod.png');
            background-position: 50% 75%;
            background-size: 90% 80%;
            background-repeat: no-repeat;
            box-shadow: 35px 35px 50px -22px rgba(0, 0, 0, .12);
        }

        @media screen and (min-width: 576px) {
            .sales-stacks-item-1 {
                background-size: 70%;
            }
        }

        @media screen and (min-width: 768px) {
            .sales-stacks-item-4 {
                box-shadow: -36px 33px 50px -22px rgba(0, 0, 0, .12);
            }
            
        }

        @media screen and (min-width: 992px) {
            .sales-stacks-item-4 {
                box-shadow: 35px 35px 50px -22px rgba(0, 0, 0, .12);
            }
        }
    }*/

    .expandable-item, .link-item {
        border-radius: 5px;
        border: 2px solid #eee;

        .expandable-item-header, .link-item-header {
            background: #eee;

            .expandable-item-header-label, .link-item-header-label {
                font-family: 'Montserrat', sans-serif;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }

    .quote-summary {
        background-color: #333;
        color: rgba(255, 255, 255, .6);

        .quote-summary-totals {
            .price {
                color: white;
            }
            
            .tip {
                color: #5da1cf;
            }
        }

        .quote-summary-key-points {
            background-color: #515151;
            border-top: 1px solid rgba(255, 255, 255, .15);

            ul li svg.svg-inline--fa {
                color: $es-pink;
            }
        }

        .quote-summary-launch {
            background-color: #111;
            color: white;
        }

        .quote-summary-actions a {
            background-color: #f7126d;
            color: white;
            text-decoration: none;
            text-transform: uppercase;
        }
    }

    .project-process {
        .project-process-divider {
            background: #222;
        }

        .project-process-block {
            .project-process-block-you {
                .project-process-block-you-line {
                    background: #222;
                }

                .project-process-block-you-circle {
                    background: white;
                    border: 2px solid $es-pink;

                    &:hover .bounce {
                        animation: bounce 1s;
                    }

                    .project-process-block-you-circle-above-horizontal {
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    .project-process-block-you-circle-top-vertical {
                        font-size: 22px;
                        font-weight: 700;
                    }
                }
            }

            .project-process-block-us {
                .project-process-block-us-item {
                    background: white;
                    border-right: 2px solid $es-pink;
                    border-bottom: 2px solid $es-pink;
                    font-size: 14px;

                    @media screen and (min-width: 992px) {
                        border-left: 2px solid $es-pink;
                    }
                }

                .project-process-block-us-item:first-child {
                    border-top: 2px solid $es-pink;

                    @media screen and (min-width: 992px) {
                        border-top: none;
                    }
                }
            }
        }
    }

    .project-stages {
        .project-stage {
            border: 2px solid $es-pink;
            background: white;

            .project-stage-info .project-stage-info-tag {
                font-family: 'Montserrat', sans-serif;
                text-transform: uppercase;

                & > span {
                    padding: 6px;
                    color: white;
                    background-color: $es-pink;
                }
            }

            .project-stage-icon {
                color: $es-pink;
            }
        }

        .project-stage-line {
            background-color: #222;
        }

        .project-stage-started {
            border-color: rgb(255, 193, 7);
            background-color: rgba(255, 193, 7, 0.2);

            .project-stage-info .project-stage-info-tag > span {
                background-color: rgb(255, 193, 7);
            }

            .project-stage-icon {
                color: rgb(255, 193, 7);
            }
        }

        .project-stage-completed {
            border-color: rgb(40, 167, 69);
            background-color: rgba(40, 167, 69, 0.2);

            .project-stage-info .project-stage-info-tag > span {
                background-color: rgb(40, 167, 69);
            }

            .project-stage-icon {
                color: rgb(40, 167, 69);
            }
        }

        .project-stage-line-completed {
            background-color: rgb(40, 167, 69);
        }
    }

    .client-body-header {
        .client-body-heading {
            font-family: 'Montserrat', sans-serif;
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .field-group .field-label {
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }


    

    @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
    .fade-in {
        opacity:0;  /* make things invisible upon start */
        animation:fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */

        animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
        animation-delay: 0.5s;
        animation-duration:0.5s;
    }
    .fade-in-1 {
        animation-delay: 1.0s
    }
    .fade-in-2 {
        animation-delay: 1.5s
    }
    .fade-in-3 {
        animation-delay: 2.0s
    }
    
    .project-sales-section {
        background-color: #000;
        padding: 40px 0 60px 0;
        color: #fff;
        margin-bottom: 20px;

        .sales-header {
            @include display-flex();
            @include flex-direction(column);
            @include align-items(center);
        }

        .component {
            background-color: #333;
            border: 1px solid #000;
        }
        .component-header {
            background-color: #222;
            padding: 5px;
            font-size: 20px;
            font-weight: 500;
        }
        .component-body {
            color: rgba(255,255,255,.6);
            font-size: 24px;
            padding: 20px;
        }
        .component-footer {
            background-color: #222;
            padding: 5px;
            color: $es-pink;
            font-size: 20px;
        }
        .component-detail {
            padding: 10px;
            background-color: #333;
            font-weight: 300;
            border-left: 10px solid #222;
            border-right: 10px solid #222;
            border-bottom: 10px solid #222;
            text-align: left;

            ul {
                margin: 0;
                padding: 5px 30px;
            }
        }
        .component-text-block {
            font-weight: 300;
        }
        
        .logo img {
            max-width:200px;
        }

        .component-footer a {
            width: 100%;
            height: 100%;
            display: block;
            color: #007bff;
        }
        .component:hover > .component-footer > a > .bounce {
            animation: bounce 1s;
        }

        .user-name {
            font-size: 26px;
            line-height: 32px;
        }

        @media screen and (min-width: 768px) {
            .sales-header {
                @include flex-direction(row);
            }
        }
    }

    .client-name {
        font-size: 24px;
        color: #555;
        font-weight: 700;
        font-family: Montserrat, sans-serif;
        text-transform: uppercase;
    }

    .client-address {
        font-size: 12px;
        line-height: 15px;
        color: #555;
        font-weight: 500;
        font-family: Montserrat, sans-serif;
        margin-top: 4px;
        margin-bottom: -6px;

        @media screen and (min-width: 768px) {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .alert-confirm-options {
        color: $es-pink;
        text-align: center;
        padding: 7px;
        border: 1px solid #f7126d;
        display: none;
    }
    .alert-confirm-options-icon {
        font-size: 30px;
    }

    .accept-project-modal .accept-text {
        text-align: center;
        font-size: 16px;
        padding: 20px;
    }
    .accept-project-modal .accept-actions {
        text-align: center;
        padding: 20px;
    }

    #editClientForm {
        .image-preview-wrapper {
            background: #000;
        }

        #editClientLogoImagePreview {
            max-width: 200px;
        }
    }


    .front-end-modal{
        a{
            color:$es-pink;
        }
        a:hover{
            color:$es-pink;
            text-decoration:underline;
        }
    }

    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-10px);
        }
        60% {
            transform: translateY(-5px);
        }
    }
}



